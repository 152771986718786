body {
	width: 100%;
	height: 100%;
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 0.5px;
	background: #f8f8f8;
}

h1,
h2,
h3 {
	font-family: "Bai Jamjuree", sans-serif;
}

h1 {
	font-size: 30px;
	font-weight: bold;
}

p {
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 0.4px;
	line-height: 2rem;
}

p,
a,
span {
	text-decoration-thickness: 1px;
	text-underline-offset: 3px;
}

input,
textarea,
span[contenteditable] {
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.5px;
}

span[contenteditable] {
	line-height: 26px;
	padding: 0.855rem 3rem;
}

input:focus,
textarea:focus {
	outline: none;
}

body.no-scroll {
	height: 100%;
	overflow: auto;
	width: 100%;
	position: fixed;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
	overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.ReactModal__Content {
	background: white;
	width: 50rem;
	max-width: calc(100vw - 2rem);
	max-height: calc(100vh - 2rem);
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
	overflow-y: auto;
	position: relative;
	padding: 0 !important;
	width: 70rem;
	border-radius: 0 !important;
	border: none !important;

	margin-top: 3rem;
	transition: 0.5s ease-in-out;
}

.ReactModalPortal {
	opacity: 0;
	transition: 0.5s ease-in-out;
}
